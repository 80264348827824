<!--
 * @Description: 业主的房屋列表
 * @Author: 琢磨先生
 * @Date: 2022-11-29 11:12:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-29 17:44:43
-->


<template>
  <el-drawer
    title="业主详情"
    v-model="visibleDrawer"
    direction="rtl"
    size="1000px"
    @closed="onClosed"
  >
    <div class="flex-column" style="height: 100%">
      <el-descriptions>
        <el-descriptions-item label="名称">{{
          model.name
        }}</el-descriptions-item>
        <el-descriptions-item label="电话">{{
          model.mobile_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="绑定微信用户">
          <div style="display: inline-block">
            <div class="flex-row item-center" v-if="model.member">
              <el-avatar :src="model.member.avatar_url" />
              <div style="margin-left: 10px; line-height: 16px">
                <div>{{ model.nick_name }}</div>
                <div>{{ model.member.mobile_phone }}</div>
              </div>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag :type="model.is_stop ? 'danger' : 'success'">{{
            model.is_stop ? "停用" : "正常"
          }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <div class="flex-grow flex-column">
        <el-table :data="model.store_list" border>
          <el-table-column label="门店" prop="shop_name" width="200"></el-table-column>
          <el-table-column label="户型" prop="huxing.name" width="180"></el-table-column>
          <el-table-column label="房号" prop="house_no"></el-table-column>
          <el-table-column label="状态" width="100">
            <template #default="scope">
              <el-tag :type="scope.row.is_stop ? 'danger' : 'success'">{{
                scope.row.is_stop ? "停用" : "正常"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="180"
            prop="create_at"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      visibleDrawer: false,
      model: {},
    };
  },
  props: ["item"],
  emits: ["close"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.model = {};
          this.visibleDrawer = true;
          this.loadDtl();
          // this.model = Object.assign({}, this.item);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = false;
      this.$http
        .get("seller/v1/landlord/dtl?id=" + this.item.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },
  },
};
</script>

<style  scoped>
.el-table {
  flex-grow: 1;
  height: 1px;
}
</style>